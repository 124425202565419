// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-common-pages-blog-post-js": () => import("./../../../src/common/pages/blog-post.js" /* webpackChunkName: "component---src-common-pages-blog-post-js" */),
  "component---src-common-pages-case-js": () => import("./../../../src/common/pages/case.js" /* webpackChunkName: "component---src-common-pages-case-js" */),
  "component---src-common-pages-categories-js": () => import("./../../../src/common/pages/categories.js" /* webpackChunkName: "component---src-common-pages-categories-js" */),
  "component---src-common-pages-consultoria-js": () => import("./../../../src/common/pages/consultoria.js" /* webpackChunkName: "component---src-common-pages-consultoria-js" */),
  "component---src-common-pages-conteudo-js": () => import("./../../../src/common/pages/conteudo.js" /* webpackChunkName: "component---src-common-pages-conteudo-js" */),
  "component---src-common-pages-curso-js": () => import("./../../../src/common/pages/curso.js" /* webpackChunkName: "component---src-common-pages-curso-js" */),
  "component---src-common-pages-na-midia-js": () => import("./../../../src/common/pages/na-midia.js" /* webpackChunkName: "component---src-common-pages-na-midia-js" */),
  "component---src-common-pages-workshop-js": () => import("./../../../src/common/pages/workshop.js" /* webpackChunkName: "component---src-common-pages-workshop-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busca-js": () => import("./../../../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-consultoria-js": () => import("./../../../src/pages/consultoria.js" /* webpackChunkName: "component---src-pages-consultoria-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-grau-de-integracao-da-comunicacao-index-js": () => import("./../../../src/pages/grau-de-integracao-da-comunicacao/index.js" /* webpackChunkName: "component---src-pages-grau-de-integracao-da-comunicacao-index-js" */),
  "component---src-pages-grau-de-integracao-da-comunicacao-iniciar-teste-js": () => import("./../../../src/pages/grau-de-integracao-da-comunicacao/iniciar-teste.js" /* webpackChunkName: "component---src-pages-grau-de-integracao-da-comunicacao-iniciar-teste-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiais-educativos-js": () => import("./../../../src/pages/materiais-educativos.js" /* webpackChunkName: "component---src-pages-materiais-educativos-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-projetos-js": () => import("./../../../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

