import "./src/scss/main.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  function checkAndAdd() { 
    if(typeof addMask !== "undefined") {
      /*global addMask */
      /*eslint no-undef: "error"*/
      addMask()
    }  else {
      setTimeout(checkAndAdd, 1000)
    }
  }
  checkAndAdd()
}